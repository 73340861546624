<template>
    <ideo-form-group :label="$t('[[[Tagi]]]')" :invalid-feedback="form.$errors.first('settings.tags')" :state="form.$errors.state('settings.tags')">
        <edito-tags v-model="form.settings.tags"></edito-tags>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('description')" :state="form.$errors.state('description')">
        <editor id="description" v-model="form.settings.description"></editor>
    </ideo-form-group>
    <ideo-form-group :invalid-feedback="form.$errors.first('showPublicationDate')" :state="form.$errors.state('showPublicationDate')">
        <ideo-form-checkbox v-model="form.settings.showPublicationDate">
            {{ $t('[[[Pokaż daty publikacji]]]') }}
        </ideo-form-checkbox>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Widok]]]')" :invalid-feedback="form.$errors.first('settings.view')" :state="form.$errors.state('settings.view')">
        <ideo-form-radio v-model="form.settings.view" name="articlesView" value="realization">{{ $t('[[[Realizacje]]]') }}</ideo-form-radio>
        <ideo-form-radio v-model="form.settings.view" name="articlesView" value="blog">{{ $t('[[[Blog]]]') }}</ideo-form-radio>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';

@Options({
    name: 'module-informations-settings'
})
export default class InformationsSettings extends Vue
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        tags: [],
        description: '',
        showPublicationDate: true,
        view: 'blog'
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}
</script>
